
import SourceFlowHead from "@sourceflow-uk/sourceflow-head";
import metaObject from "../../.sourceflow/metadata.json";

export default function SeoHead({
    site_name = "Carbon60",
    page_name = "Project engineering and technical recruitment specialists | Carbon60",
    description = "Project engineering and technical recruitment specialists.",
    canonical_link = "",
    og_image = "https://www.carbon60global.com/site-assets/images/og-image.jpg",
    og_img_alt = "Project engineering and technical recruitment specialists | Carbon60",
}) {
    const canonical_url = `https://www.carbon60global.com/${canonical_link ? canonical_link : ""}`;
    return (
        <>
            <SourceFlowHead
                metaObject={metaObject}
                title={page_name}
                description={description}
                additionalMetaTags={[{
                    property: 'og:site_name',
                    content: site_name
                }, {
                    property: 'title',
                    content: page_name
                },
                ]}
                images={[
                    {
                        url: og_image,
                        width: 1200,
                        height: 600,
                        alt: og_img_alt,
                        type: "image/jpeg",
                    },
                ]}
                locale={'en_GB'}
                canonical={canonical_url}
            />
        </>
    )
}