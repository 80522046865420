import SourceFlowImage from "@sourceflow-uk/sourceflowimage"
import SourceFlowText from "@sourceflow-uk/sourceflowtext"
import JobSearch from "@sourceflow-uk/job-search";
import {
    Container,
    Row,
    Col
} from 'reactstrap';
import Link from "next/link"
import styles from './styles.module.scss';
import Arrow from "/public/site-assets/svg/arrow.svg"
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { motion, useTransform, useScroll } from 'framer-motion';
import BannerOverlay from "/public/site-assets/svg/banner-overlay.svg"
import LogoIcon from "/public/site-assets/svg/logo-icon.svg"
import imageMetaData from "../../.sourceflow/image_metadata.json";

export default function MainBanner({
    global,
    pathPrefix,
    customClass = "",
    content,
    imageURL1,
    imageURL2,
    imageALT1,
    imageALT2,
    jobSearch = false,
    displayImage1 = true,
    pageAnchor = false,
    editableContent = true,
    backgroundImage = false,
    locale = "https://www.lorienglobal.com"
}) {
    const variants_1 = {
        hidden: { opacity: 0.005, y: 40 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.5, delay: 0.05 } },
    };
    const variants_2 = {
        hidden: { opacity: 0.005, y: 40 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.5, delay: 0.2 } },
    };
    return (
        <section className={`${styles.root} ${customClass} position-relative overflow-hidden ${displayImage1 ? null : "hidden-image-1"}`}  style={{ backgroundImage: "url(" + backgroundImage + ")", backgroundRepeat: "no-repeat", backgroundSize: "cover"}}>
            <span className="position-absolute icon-overlay">
                <LogoIcon />
            </span>
            <Container>
                <Row className="m-0">
                    <Col lg={6} className="contentWrapper d-flex flex-wrap align-items-center position-relative">
                        <div>
                            <motion.div initial="hidden" animate="visible" variants={variants_1}>
                                {editableContent ?
                                    <SourceFlowText global={global} path={`${pathPrefix}.content`} type="html">
                                        {content}
                                    </SourceFlowText>
                                    : content}
                            </motion.div>
                            {jobSearch ?
                                <motion.div initial="hidden" animate="visible" variants={variants_2}>
                                    <JobSearch
                                        options={{
                                            searchResults: false,
                                            searchFilters: false,
                                            submitDestination: `${locale ? locale : ""}/jobs`,
                                        }}
                                        translations={{
                                            "input.placeHolder": "Job Title or Keyword",
                                        }}
                                    />
                                </motion.div> : null}

                        </div>
                    </Col>
                    {displayImage1 ?
                        imageURL1 ?
                            <Col lg={6} className="position-relative mainImageWrapper">
                                <SourceFlowImage
                                    src={imageURL1}
                                    size="1000x"
                                    alt={imageALT1}
                                    className="card-img rounded-4"
                                    imagesMetaData={imageMetaData}
                                    path={pathPrefix ? pathPrefix : (`${pathPrefix}.image`) }
                                />
                            </Col> : ""
                        : null}
                    <div className="imageWrapper position-absolute p-0 text-end">
                        {/* <SourceFlowImage
                            src={imageURL2}
                            size="1000x"
                            alt={imageALT2}
                            className="card-img rounded-0"
                            lazyload="false"
                        /> */}
                        {/* <img src={`${imageURL2}`} alt={`${imageALT2}`} className="card-img rounded-0"></img> */}
                        {/* <BannerOverlay /> */}
                    </div>

                </Row>
                {pageAnchor ?
                    <AnchorLink offset='80' href={`#${pageAnchor}`} className="banner-anchor position-absolute" aria-label="Scroll Bottom">
                        <Arrow />
                    </AnchorLink> : null}

            </Container>
        </section>
    )
}
